import React, { useRef, useEffect, useState, useContext } from "react";
import { WaveEnd, WaveStart } from "assets";
import { FX, Image, Text } from "components";
import { motion } from "framer-motion";

import {
  FaArrowRight,
  FaBullhorn,
  FaBullseye,
  FaVolumeHigh,
} from "react-icons/fa6";
import { WindowContext } from "context";
export default function Facts() {
  const factsRef = useRef(null);

  return (
    <div className="relative z-20 w-full ">
      <img
        src={WaveStart}
        alt="Wave Start"
        className="absolute top-0 -translate-y-[calc(100%-1px)] min-w-screen "
      />
      <div
        ref={factsRef}
        className="flex flex-col items-center justify-center w-full h-full gap-5 py-5 text-center bg-primary"
      >
        <div className="h-full px-2 sm:w-5/6 sm:px-0 max-w-7xl">
          <motion.div
            className="relative mb-4 overflow-hidden rounded-md h-14 bg-bright"
            initial={{ scale: 0.95 }}
            whileInView={{ scale: 1 }}
          >
            <Text.Header
              className={
                "!text-primary overflow-hidden mb-7 relative h-fit uppercase text-4xl w-full"
              }
            >
              Our Process
            </Text.Header>
            <Hazard />
          </motion.div>
          <div className="grid items-center justify-center h-full grid-cols-12 gap-4 max-lg:flex-col lg:flex-wrap max-w-7xl 2xl:flex-nowrap">
            <FactBox
              header={"Production"}
              icon={<FaVolumeHigh />}
              projectID={"royalty"}
            >
              Comprehensive Production, Recording, and Engineering services.
              From capturing the raw essence of your sound to fine-tuning every
              detail, our team ensures your music reaches its full potential.
            </FactBox>
            <FactBox
              header={"Distribution"}
              icon={<FaBullseye />}
              projectID={"sdqnaqi"}
            >
              Once your masterpiece is crafted, our Distribution and Marketing
              services kick in to catapult your music into the spotlight. We
              ensure your music reaches the right audience at the right time.
            </FactBox>
            <FactBox
              header={"Marketing"}
              icon={<FaBullhorn />}
              projectID={"forge"}
            >
              Our commitment extends to Post-Marketing, where we continue to
              nurture your music's success. Whether it's engaging with fans,
              exploring new opportunities, or refining your brand, we're with
              you every step of the way.
            </FactBox>
          </div>
        </div>
      </div>
      <img
        src={WaveEnd}
        alt="Wave Start"
        className="absolute bottom-0 translate-y-[calc(100%-1px)]"
      />
    </div>
  );

  function FactBox({ children, header, icon, projectID }) {
    const map = new Array(3).fill(0);
    const [hover, setHover] = useState(false);
    const { mobile } = useContext(WindowContext);
    return (
      <a
        onMouseEnter={() => !mobile && setHover(true)}
        onMouseLeave={() => !mobile && setHover(false)}
        href={`/projects/${projectID}`}
        className={`grid group sm:cursor-pointer overflow-hidden relative lg:col-span-4 col-span-12 hover:scale-105 duration-500 min-w-full transition-transform lg:min-h-[20rem] h-full lg:min-w-[10rem] pb-10 bg-bright rounded-lg p-5 `}
      >
        <div
          className={`flex w-full flex-col items-start justify-start h-full`}
        >
          <div className="flex flex-col items-center justify-center w-full gap-5 h-28 animate-fade-in-left">
            <FX.ShowWhenViewed useRef={factsRef}>
              <div className="text-3xl text-secondary">{icon}</div>

              <Text.Header
                className={
                  "!text-lg relative text-center !text-pretty !w-fit !font-bold z-10 !text-black"
                }
              >
                {header}
                <div className="absolute bottom-0 w-1/2 h-1 transition-all translate-y-2 group-hover:w-full -z-10 bg-primary/20" />
              </Text.Header>
            </FX.ShowWhenViewed>
          </div>
          <FX.ShowWhenViewed useRef={factsRef}>
            <Text.Body className={" text-left !text-sm/loose"}>
              {children}
            </Text.Body>
          </FX.ShowWhenViewed>
          {(hover || mobile) && <LinkPointer />}
          <MiniHazard />
        </div>
      </a>
    );
    function LinkPointer() {
      return (
        <div className="absolute bottom-0 left-0 flex items-center justify-center w-full gap-2 p-2 pointer-events-none text-dark ">
          <Text.Body className={"w-fit !animate-fade-in-up-fast !text-sm"}>
            View Related Project
          </Text.Body>
          <FaArrowRight className="animate-fade-in-up-fast" />
        </div>
      );
    }
    function MiniHazard() {
      const map = new Array(3).fill(0);
      return (
        <>
          <div className="absolute top-0 left-0  flex items-center justify-center w-[15px] h-[50px] ">
            <div className="flex items-center justify-center h-[1000px] rotate-45 left-right">
              {map.map((i) => (
                <>
                  <motion.div className="bg-primary  h-full w-[5px]" />
                  <motion.div className="bg-transparent h-full w-[5px]" />
                </>
              ))}
            </div>
          </div>
          <div className="absolute bottom-0 right-0  flex items-center justify-center w-[0px] h-[50px] ">
            <div className="flex items-center justify-center h-[1000px] rotate-45 left-right">
              {map.map((i) => (
                <>
                  <motion.div className="bg-primary  h-full w-[5px]" />
                  <motion.div className="bg-transparent h-full w-[5px]" />
                </>
              ))}
            </div>
          </div>
        </>
      );
    }
  }

  function Hazard() {
    const map = new Array(8).fill(0);
    return (
      <div className="absolute top-0 flex items-center justify-center w-full h-full transition-opacity -translate-x-1/2 opacity-0 sm:opacity-100 left-1/2">
        <div className="flex items-center justify-center h-[1000px] rotate-45 left-right">
          {map.map((i) => (
            <>
              <motion.div
                animate={{ x: 150, transition: { delay: 0, duration: 0 } }}
                whileInView={{ x: -15 }}
                transition={{ delay: 0.25, duration: 2 }}
                className="bg-white z-10 h-full w-[25px]"
              />
              <motion.div
                animate={{ x: 150, transition: { delay: 0, duration: 0 } }}
                whileInView={{ x: -15 }}
                transition={{ delay: 0.25, duration: 2 }}
                className="bg-primary  h-full w-[25px]"
              />
            </>
          ))}
          <div className="bg-transparent  h-full w-[275px]" />

          {map.map((i) => (
            <>
              <motion.div
                animate={{ x: -150, transition: { delay: 0, duration: 0 } }}
                whileInView={{ x: 15 }}
                transition={{ delay: 0.25, duration: 2 }}
                className="bg-primary  h-full w-[25px]"
              />
              <motion.div
                animate={{
                  x: -150,
                  transition: { delay: 0, duration: 0 },
                }}
                whileInView={{ x: 15 }}
                className="bg-white z-10 h-full w-[25px]"
                transition={{
                  delay: 0.25,
                  duration: 2,
                }}
              />
            </>
          ))}
        </div>
      </div>
    );
  }
}

import React, { useState, useEffect } from "react";

export default function ShowWhenViewed({ children, useRef, threshold = 0.1 }) {
  const [refVisible, setDivRefVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivRefVisible(true);
        } else {
          setDivRefVisible(false);
        }
      },
      { threshold: threshold }
    );
    if (useRef?.current) {
      observer.observe(useRef.current);
    }
    return () => {
      if (useRef?.current) {
        observer.unobserve(useRef.current);
      }
    };
  }, [useRef]);

  return (
    <>{refVisible ? children : <div className="invisible">{children}</div>}</>
  );
}

import React from "react";

export default function Badge({ className, children }) {
  return (
    <div
      className={`${className} animate-fade-in-left text-xs rounded-full group-hover:animate-fade-in-up-fast p-1 bg-black px-2 w-fit h-fit text-bright whitespace-nowrap font-montserrat font-medium`}
    >
      {children}
    </div>
  );
}

import React, { useEffect, useState, createContext } from "react";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
const FirebaseContext = createContext();

const firebaseConfig = {
  apiKey: "AIzaSyCfXq5C6Acl5B7GIz65dhU58vtexR_s6GI",
  authDomain: "fir-forever-midwest.firebaseapp.com",
  databaseURL: "https://fir-forever-midwest-default-rtdb.firebaseio.com",
  projectId: "fir-forever-midwest",
  storageBucket: "fir-forever-midwest.appspot.com",
  messagingSenderId: "655092390094",
  appId: "1:655092390094:web:5adc1b3e20ee7873add89f",
  measurementId: "G-0WWVY1LFXL",
};

const FirebaseProvider = ({ children }) => {
  const [app, setApp] = useState(null);
  const [auth, setAuth] = useState(null);
  const [functions, setFunctions] = useState(null);
  const [firestore, setFirestore] = useState(null);
  const [storage, setStorage] = useState(null);
  const [analytics, setAnalytics] = useState(null);

  const [doneLoading, setDoneLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    let timeout = 50;
    async function Unsub() {
      const appResponse = initializeApp(firebaseConfig);
      const authResponse = getAuth(appResponse);
      const functionsResponse = getFunctions(appResponse);
      const firestoreResponse = getFirestore(appResponse);
      const storageResponse = getStorage(appResponse);
      const analyticsResponse = getAnalytics(appResponse);

      const [app, auth, functions, firestore, storage, analytics] =
        await Promise.all([
          appResponse,
          authResponse,
          functionsResponse,
          firestoreResponse,
          storageResponse,
          analyticsResponse,
        ]);

      setApp(app);
      setAuth(auth);
      setFunctions(functions);
      setFirestore(firestore);
      setStorage(storage);
      setAnalytics(analytics);

      setDoneLoading(true);
    }
    setTimeout(() => {
      if (mounted) {
        Unsub();
      }
    }, [timeout]);
    return () => (mounted = false);
  }, []);

  // gets app property
  function getApp() {
    return app;
  }
  function useAuth() {
    return auth;
  }

  function useFunctions() {
    return functions;
  }

  function useFirestore() {
    return firestore;
  }

  function useStorage() {
    return storage;
  }

  function useAnalytics() {
    return analytics;
  }

  const values = {
    getApp,
    useAuth,
    useFunctions,
    useFirestore,
    useStorage,
    useAnalytics,
  };
  return (
    <FirebaseContext.Provider value={values}>
      {doneLoading && children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseProvider, FirebaseContext };

import React, { useEffect, useState } from "react";

import { createContext, useContext } from "react";
import { httpsCallable } from "firebase/functions";
import { FirebaseContext, MessageContext } from "context";

const FunctionsContext = createContext();

const FunctionsProvider = ({ children }) => {
  const { useFunctions, useAuth } = useContext(FirebaseContext);
  const { newMessage } = useContext(MessageContext);
  const [doneLoading, setDoneLoading] = useState(false);
  const functions = useFunctions();

  useEffect(() => {
    let mounted = true;
    let timeout = 50;

    setTimeout(async () => {
      if (mounted) {
        setDoneLoading(true);
      }
    }, [timeout]);

    return () => (mounted = false);
  }, []);

  const UseSendContactMessage = async (email, message) => {
    // checks if user exists
    const auth = useAuth();
    if (auth.currentUser) {
      //gets cloud function
      const sendContactMessage = httpsCallable(functions, "sendContactMessage");
      /* try {
        // sends response
        sendContactMessage({
          email: email,
          message: message,
        })
          .then((result) => {
            setTimeout(() => {
              console.log(result);
              newMessage({
                type: result.data.success ? "success" : "error",
                duration: 5000,
                message: result.data.message,
              });

              return result.data;
            }, 500);
          })
          .catch((error) => {
            newMessage({
              type: "error",
              duration: 5000,
              message: "Error sending message",
            });
            return {
              message: "Error sending message",
              success: false,
            };
          });
      } catch (error) {
        console.error("sendContactMessage Failed", error);
        throw error; // Rethrow the error to be caught by the caller
      }*/

      const result = await sendContactMessage({
        email: email,
        message: message,
      });
      console.log(result);
      let data = await result.data;
      if (data.success) {
        newMessage({
          type: "success",
          duration: 5000,
          message: data.message,
        });
      } else {
        newMessage({
          type: "error",
          duration: 5000,
          message: data.message,
        });
      }

      return data;
    }
  };

  const values = { UseSendContactMessage };
  return (
    <FunctionsContext.Provider value={values}>
      {doneLoading && children}
    </FunctionsContext.Provider>
  );
};

export { FunctionsProvider, FunctionsContext };

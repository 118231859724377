import React from "react";
import { Button, FX, Image, Text } from "components";
import { motion } from "framer-motion";
import {
  RecordingStudioFour,
  RecordingStudioThree,
  RecordingStudioTwo,
} from "assets";
import { FaArrowRight } from "react-icons/fa6";
export default function Hero() {
  let hover = false;

  const setHover = (value) => {
    hover = value;
  };

  return (
    <div className="w-full h-[calc(100dvh-5rem)] overflow-clip">
      <div className="relative flex items-start justify-center h-full sm:items-center ">
        <div class="absolute inset-0 h-full w-full bg-transparent bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />

        <div className="grid w-full max-w-6xl px-10 mt-10 sm:-mt-20 gap-y-10 sm:grid-cols-2">
          <div className="z-30 block p-2 transition-all duration-200 rounded-md hovershadow-sm h-fit hover:bg-bright/75 hover:drop-shadow-md hover:backdrop-blur-sm w-fit ">
            <Text.Header className={"!text-xl text-black !font-light pb-2"}>
              Wecome to,
            </Text.Header>
            <Text.Header
              className={
                "text-5xl !font-mono !font-extrabold !text-black !tracking-tight !animate-fade-in-up pb-2"
              }
            >
              <FX.Matrix>Forever Midwest</FX.Matrix>
            </Text.Header>
            <div>
              <Text.Body
                className={
                  "!text-2xl pb-5 font-semibold !text-black !animate-fade-in-up"
                }
              >
                We are a full-service production house dedicated to the arts.
              </Text.Body>
              <Button.Outline
                href={"contact"}
                className={`pointer-events-auto group`}
              >
                Get in touch
                <FaArrowRight className="ml-2 " />
              </Button.Outline>
            </div>
          </div>
          <div className="relative flex justify-center w-full">
            <motion.div
              className="absolute bottom-0 hidden sm:block right-6"
              animate={{ y: "-250%", rotate: -20 }}
              transition={{ duration: 20, ease: "easeOut" }}
              whileHover={{ rotate: 0, scale: 1.5 }}
            >
              <Image className={"w-16 h-24 "} src={RecordingStudioThree} />
            </motion.div>
            <motion.div
              animate={{ y: -2, rotate: 5 }}
              whileHover={{ rotate: 0, scale: 1.5 }}
              transition={{ duration: 20, ease: "easeOut" }}
              className="hidden sm:block"
            >
              <Image className={"w-64 h-96"} src={RecordingStudioFour} />
            </motion.div>
            <motion.div
              className="absolute top-0 hidden left-6 sm:block"
              animate={{ y: "150%", rotate: 20 }}
              whileHover={{ rotate: 0, scale: 1.5 }}
              transition={{ duration: 20, ease: "easeOut" }}
            >
              <Image className={"w-16 h-24 "} src={RecordingStudioTwo} />
            </motion.div>
          </div>
        </div>
      </div>
      {/**
        <div
          onClick={() => {
            handleScroll();
          }}
          className="absolute flex flex-col items-center justify-center text-4xl text-center -translate-x-1/2 cursor-pointer bottom-10 animate-shine left-1/2"
        >
          <Text.Header className={"text-xl"}>Scroll Down</Text.Header>
          <FaChevronDown />
        </div> */}
    </div>
  );
}

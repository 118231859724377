import React, { useState } from "react";

export default function Image({ src, alt = "alt", className }) {
  return (
    <div
      className={`${className} block skeleton rounded-md overflow-hidden animate-fade-in-up`}
    >
      <img src={src} alt={alt} className={`object-cover w-full h-full `} />
    </div>
  );
}

import React, { useEffect, useState } from "react";

export default function Matrix({ children = "Matrix" }) {
  const alphabet =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  const word = children;
  const [final, setFinal] = useState("");
  useEffect(() => {
    let letter = 0;
    let interval = setInterval(() => {
      let wordArray = [];
      for (let i = 0; i < word.length; i++) {
        if (i > letter && word[i] !== " ")
          wordArray.push(alphabet[Math.floor(Math.random() * alphabet.length)]);
        else wordArray.push(word[i]);
      }
      setFinal(wordArray.join(""));
      letter++;
      if (letter > word.length) {
        clearInterval(interval);
      }
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <>{final}</>;
}

import { LightLogo } from "assets";
import { Button, Text } from "components";
import React from "react";

export default function Footer() {
  return (
    <div className="flex items-center justify-center w-full p-2 pt-10  bg-primary">
      <div className="w-full max-w-7xl">
        <div className="grid w-full grid-cols-12 mb-6 gap-10">
          <div className="flex justify-start gap-2 items-start flex-col w-fit h-full sm:col-span-4 col-span-12">
            <Text.Header
              flex
              className={`!text-bright whitespace-nowrap text-center sm:text-xl text-base`}
            >
              <img
                src={LightLogo}
                alt="Forever Midwest Light Logo"
                className="sm:h-[28px] h-[24px]"
              />
              Forever Midwest
            </Text.Header>
            <Text.SubHeader className={`!text-bright  text-center !text-sm`}>
              Full-Service Production House
            </Text.SubHeader>
          </div>
          <div className="flex sm:justify-evenly flex-wrap h-full sm:col-span-8 col-span-12 gap-4 !text-left w-fit sm:place-self-end">
            <FCol title={"Company"} links={["about", "contact", "projects"]} />
            <FCol title={"Resources"} links={["privacy", "terms"]} />
          </div>
        </div>
        <div className="block w-full mb-2 h-[1px] bg-bright" />

        <Text.Body className={`!text-bright text-center !text-sm`}>
          © 2024 Forever Midwest
        </Text.Body>
      </div>
    </div>
  );
  function FCol({ title, links }) {
    return (
      <div className="max-w-full sm:w-fit sm:min-w-[150px] w-full">
        <Text.Header className={`!text-bright uppercase !text-sm pb-6`}>
          {title}
        </Text.Header>
        <div className="flex flex-col gap-4">
          {links.map((link) => (
            <Button.Footer
              href={`/${link}`}
              className={`!text-bright/80 capitalize hover:!text-bright transition-colors !text-xs font-normal`}
            >
              {link}
            </Button.Footer>
          ))}
        </div>
      </div>
    );
  }
}

import Text from "components/Text";
import React from "react";
import { FaHouse, FaInfo } from "react-icons/fa6";

export default function Top() {
  return (
    <div className="sm:flex hidden items-center justify-center w-full h-5 !transition-all *:!duration-500 bg-black group">
      <div className="flex justify-end w-5/6 gap-6 max-w-7xl">
        <Text.Header
          flex
          className={
            "!text-xs group-hover:text-bright text-bright/50 *:!gap-1 !font-normal justify-center items-center"
          }
        >
          <FaInfo className="my-auto !text-[12px] group-hover:bg-bright text-black bg-bright/50 rounded-full p-[2px]" />{" "}
          contact@forevermidwest.com
        </Text.Header>
        <Text.Header
          flex
          className={
            "!text-xs group-hover:text-bright text-bright/50 *:!gap-1 !font-normal justify-center items-center"
          }
        >
          <FaHouse className="my-auto !text-[12px] text-black group-hover:bg-bright bg-bright/50 rounded-full p-[2px]" />{" "}
          Des Plaines, IL
        </Text.Header>
      </div>
    </div>
  );
}

import { WindowContext, WindowProvider } from "./Window";
import React from "react";
import { FirebaseProvider, FirebaseContext } from "./firebase/App";
import { AuthProvider, AuthContext } from "./firebase/Auth";
import { FunctionsProvider, FunctionsContext } from "./firebase/Functions";
import { FirestoreProvider, FirestoreContext } from "./firebase/Firestore";
import { StorageProvider, StorageContext } from "./firebase/Storage";
import { AnalyticsProvider, AnalyticsContext } from "./firebase/Analytics";
import { MessageProvider, MessageContext } from "./Message";

const ContextProviders = ({ children }) => {
  return (
    <MessageProvider>
      <FirebaseProvider>
        <FunctionsProvider>
          <AuthProvider>
            <FirestoreProvider>
              <StorageProvider>
                <AnalyticsProvider>
                  <WindowProvider>
                    <>{children}</>
                  </WindowProvider>
                </AnalyticsProvider>
              </StorageProvider>
            </FirestoreProvider>
          </AuthProvider>
        </FunctionsProvider>
      </FirebaseProvider>
    </MessageProvider>
  );
};

export {
  ContextProviders,
  FirebaseContext,
  AuthContext,
  FunctionsContext,
  FirestoreContext,
  StorageContext,
  AnalyticsContext,
  WindowContext,
  MessageContext,
};

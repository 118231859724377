import React from "react";
import Container from "./Container";
export default function Message({ type = "error", children = "Message" }) {
  if (type === "error") {
    return (
      <Container
        className={` animate-message opacity-0 drop-shadow-none !w-fit desktop:min-w-96 p-2 bg-red-500/10 outline-red-500 text-red-500 outline outline-1 text-center`}
      >
        {children}
      </Container>
    );
  }
  if (type === "accepted" || type === "success") {
    return (
      <Container
        className={` animate-message opacity-0 drop-shadow-none !w-fit desktop:min-w-96 p-2 bg-blue-500/10 outline-blue-500 text-blue-500 outline outline-1 text-center`}
      >
        {children}
      </Container>
    );
  }
}

import React, { useEffect, useState } from "react";

import { createContext, useContext } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInAnonymously,
  signOut,
} from "firebase/auth";
import { FirebaseContext, FunctionsContext, MessageContext } from "context";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { useAuth } = useContext(FirebaseContext);
  const { useGetCustomClaims } = useContext(FunctionsContext);
  const { newMessage } = useContext(MessageContext);
  const [doneLoading, setDoneLoading] = useState(false);
  const [doneLoadingUser, setDoneLoadingUser] = useState(false);
  const auth = useAuth();
  const [user, setUser] = useState(null);
  const useAnon = true;
  function UseAuthCallback(user) {
    if (user) {
      // User is signed in, see docs for a list of available properties
      setUser(user);
      console.log(user);
      setDoneLoadingUser(true);
    } else {
      // User is signed out
      setUser(null);
      // if useAnon is true, sign in anonymously
      if (useAnon)
        signInAnonymously(auth)
          .then((user) => {
            setUser(user);
          })
          .catch((error) => console.error(error));
      setDoneLoadingUser(true);
    }
  }

  useEffect(() => {
    let mounted = true;
    let timeout = 50;

    setTimeout(async () => {
      if (mounted) {
        onAuthStateChanged(auth, (user) => {
          UseAuthCallback(user);
        });
        setDoneLoading(true);
      }
    }, [timeout]);
    return () => (mounted = false);
  }, []);

  // does user have this claim
  const HasCustomClaim = async (claimID) => {
    const customClaims = await useGetCustomClaims();
    if (!customClaims) return false;
    const claimExist = customClaims.hasOwnProperty(claimID);
    return claimExist;
  };

  const SignIn = async ({ email, password }) => {
    // if email or password don't exist
    if (!email || !password) {
      return { success: false, message: "Something went wrong!" };
    }
    try {
      console.log(email, password);
      const response = await signInWithEmailAndPassword(auth, email, password);
      const userResponse = response.user;
      setUser(userResponse);
      if (userResponse) {
        return { success: true, message: "signed in" };
      }
    } catch (error) {
      console.error(error);
    }
    return { success: false, message: "Something went wrong!" };
  };

  const useSignOut = async () => {
    try {
      signOut(auth);
      newMessage({
        type: "success",
        message: "Successfully signed out",
      });
    } catch (error) {
      console.error(error);
      newMessage({
        type: "error",
        message: "Error signing out",
      });
    }
  };

  const values = { HasCustomClaim, SignIn, useSignOut, user };
  return (
    <AuthContext.Provider value={values}>
      {doneLoading && doneLoadingUser && children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };

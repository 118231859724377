import "./App.css";
import React from "react";
import { Footer, Messages, Nav } from "components";
import { Router } from "router";

function App() {
  return (
    <div className="h-full min-h-[calc(100%-4.1rem)] scroll-smooth bg-bright">
      <Nav />
      <Messages.Container />
      <Router />
      <Footer />
    </div>
  );
}

export default App;

import React, { useContext } from "react";

import { WindowContext } from "context";

export default function Container({ colSpan, className, children }) {
  const { mobile } = useContext(WindowContext); // context for if device is mobile

  const span = colSpan || 1;

  return (
    <div
      className={`${className} w-full p-2 bg-white rounded drop-shadow-md`}
      style={
        mobile
          ? { gridColumn: "span 12" }
          : {
              gridColumn: "span " + span + " / span " + span,
            }
      }
    >
      {children}
    </div>
  );
}

import React from "react";
import { TailSpin } from "react-loading-icons";

const FormButton = ({
  type = "Primary",
  onMouseUp,
  className,
  children,
  doneLoading = true,
}) => {
  if (type === "Primary") {
    return (
      <>
        {doneLoading ? (
          <button
            type="submit"
            onMouseUp={onMouseUp}
            className={`${className} flex justify-center gap-1 items-center py-3 my-1 px-4 text-sm/none select-none tracking-tight transition-colors hover:bg-dark/80 font-medium text-bright rounded-md cursor-pointer pointer-events-auto font-montserrat w-fit bg-dark`}
          >
            {children}
          </button>
        ) : (
          <div
            className={`${className} flex justify-center gap-1 items-center py-3 my-1 px-4 text-sm/none select-none tracking-tight transition-colors bg-dark/80 font-medium text-bright rounded-md font-montserrat w-fit`}
          >
            <TailSpin
              className="h-[.875rem] w-[.875rem]  mr-1 overflow-visible"
              strokeWidth={5}
            />{" "}
            Please Wait
          </div>
        )}
      </>
    );
  }
  if (type === "Secondary") {
    return (
      <>
        {doneLoading ? (
          <button
            onMouseUp={onMouseUp}
            className={`${className} flex justify-center items-center gap-1 py-3 my-1 px-4 text-sm/none select-none tracking-tight transition-colors hover:bg-dark/5 text-dark rounded-md cursor-pointer pointer-events-auto   font-montserrat w-fit bg-dark/10 hover:drop-shadow-lg`}
          >
            {children}
          </button>
        ) : (
          <div
            onMouseUp={onMouseUp}
            className={`${className} flex justify-center items-center gap-1 py-3 my-1 px-4 text-sm/none select-none tracking-tight transition-colors bg-dark/5 text-dark rounded-md font-montserrat w-fit`}
          >
            <TailSpin
              className="h-[.875rem] w-fit mr-1  overflow-visible"
              stroke="#313638"
              strokeWidth={5}
            />{" "}
            Please Wait
          </div>
        )}{" "}
      </>
    );
  }
  if (type === "Outline") {
    return (
      <>
        {doneLoading ? (
          <div
            onMouseUp={onMouseUp}
            className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 transition-colors hover:bg-dark/5 select-none font-medium text-dark rounded-md cursor-pointer pointer-events-auto  font-montserrat w-fit bg-transparent outline outline-1 -outline-offset-1 outline-dark/20 hover:drop-shadow-lg`}
          >
            {children}
          </div>
        ) : (
          <div
            onMouseUp={onMouseUp}
            className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 transition-colors bg-dark/5 select-none font-medium text-dark rounded-md font-montserrat w-fit  outline outline-1 -outline-offset-1 outline-dark/20`}
          >
            <TailSpin
              className="h-[.875rem] w-fit mr-1 overflow-visible"
              stroke="#313638"
              strokeWidth={5}
            />{" "}
            Please Wait
          </div>
        )}
      </>
    );
  }
  if (type === "Ghost") {
    return (
      <>
        <div
          onMouseUp={onMouseUp}
          className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 transition-colors hover:bg-dark/5 select-none font-medium text-dark rounded-md cursor-pointer pointer-events-auto  font-montserrat w-fit bg-transparent`}
        >
          {children}
        </div>
      </>
    );
  }
  if (type === "Link") {
    return (
      <>
        <div
          onMouseUp={onMouseUp}
          className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 hover:underline transition-colors underline-offset-4 select-none font-medium text-dark rounded-md cursor-pointer pointer-events-auto  font-montserrat w-fit bg-transparent`}
        >
          {children}
        </div>
      </>
    );
  }
};
export default FormButton;

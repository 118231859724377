import React, { useEffect, useState } from "react";

import { createContext, useContext } from "react";
import { setUserId } from "firebase/analytics";
import { FirebaseContext, AuthContext } from "context";

const AnalyticsContext = createContext();

const AnalyticsProvider = ({ children }) => {
  const { useAnalytics } = useContext(FirebaseContext);
  const { user } = useContext(AuthContext);
  const [doneLoading, setDoneLoading] = useState(false);
  const analytics = useAnalytics();

  function analyticsCallback() {
    if (!user) return;
    setUserId(analytics, user.uid);
  }

  useEffect(() => {
    let timeout = 50;
    let mounted = true;

    setTimeout(async () => {
      if (mounted) {
        analyticsCallback();
        setDoneLoading(true);
      }
    }, [timeout]);
    return () => (mounted = false);
  }, [user]);
  const values = {};
  return (
    <AnalyticsContext.Provider value={values}>
      {doneLoading && children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsProvider, AnalyticsContext };

import { Image, Text } from "components";
import React, { useEffect } from "react";

export default function About() {
  useEffect(() => {
    let mounted = true;
    let timeout = 50;

    setTimeout(() => {
      if (mounted) {
        document.title = "Forever Midwest | About";
      }
    }, [timeout]);
  }, []);
  return (
    <div className="flex items-start justify-center flex-grow w-full min-h-[calc(100dvh-4.1rem)] h bg-dark/5">
      <div className="flex flex-col sm:w-5/6 min-h-full gap-5 px-10 py-10 max-w-7xl">
        <div className="flex flex-col items-start justify-center w-full gap-2 p-2 pb-0 sm:jusify-start ">
          <Text.Header>About</Text.Header>

          <Text.SubHeader>Who We Are.</Text.SubHeader>
          <Text.Body className={"pb-4"}>
            Forever Midwest LLC is a production house based out of Chicago, IL.
            We utilize branding to push important messages.
          </Text.Body>
          <Image
            src={
              "https://firebasestorage.googleapis.com/v0/b/fir-forever-midwest.appspot.com/o/project_images%2Flive_show_at_forge.jpg?alt=media&token=4f2a94a1-4cba-444a-8a0f-2db225ab29e1"
            }
            className={" h-64"}
          />
          <Text.SubHeader>What We Do.</Text.SubHeader>
          <Text.Body>
            To put it simply, Forever Midwest pushes the limit of sounds. We
            help artists do everything from idea-generation to post-marketing.
          </Text.Body>
        </div>
      </div>
    </div>
  );
}

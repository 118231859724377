import { FX, Text } from "components";
import { motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { WindowContext } from "context";

export default function FAQ() {
  const [openAccordion, setOpenAccordion] = useState(null);
  const FaqRef = useRef(null);

  return (
    <div
      ref={FaqRef}
      className="flex justify-center w-full h-full p-10 px-2 sm:px-0 bg-dark/5"
    >
      <div className="grid grid-cols-12 gap-2 sm:w-5/6 max-w-7xl">
        <div className="col-span-12 sm:col-span-5">
          <Text.Header className={" !text-black !font-bold text-4xl"}>
            FAQ
          </Text.Header>
          <Text.Body>Answers to some common questions</Text.Body>
        </div>
        <div className={"sm:col-span-7 col-span-12 gap-2 flex flex-col"}>
          <Accordion
            openAccordion={openAccordion}
            setOpenAccordion={setOpenAccordion}
            id={1}
            title="How do you promote artists?"
            FaqRef={FaqRef}
          >
            Through various methods. Our team in proficient in tools like Google
            Adsense, Meta Ads Manager, and our own custom toolchains. We also
            have many relationships with influencers and playlists that we
            utilize to make sure your sounds are heard.
          </Accordion>
          <Accordion
            openAccordion={openAccordion}
            setOpenAccordion={setOpenAccordion}
            id={2}
            title="Do you take royalties?"
            FaqRef={FaqRef}
          >
            Our team is dedicated to make sure that Forever Midwest Distribution
            Partners keep 100% of their earnings. No royalty is taken from the
            earnings.
          </Accordion>
          <Accordion
            openAccordion={openAccordion}
            setOpenAccordion={setOpenAccordion}
            id={3}
            title="What is a full service production house?"
            FaqRef={FaqRef}
          >
            Forever Midwest is along with you every step of the way. Our
            multi-talented team is capible of assisting your project from idea
            generation to after the release.
          </Accordion>
        </div>
      </div>
    </div>
  );
}
const Accordion = ({
  id,
  title = "lorem ipsum",
  children = "lorem ipsum",
  openAccordion,
  setOpenAccordion,
  FaqRef,
}) => {
  const { mobile } = useContext(WindowContext);
  return (
    <motion.div
      onClick={() => {
        if (openAccordion === id) setOpenAccordion(null);
        else setOpenAccordion(id);
      }}
      className={` flex overflow-hidden flex-col pointer-events-auto cursor-pointer bg-bright rounded-md`}
      animate={{ height: openAccordion === id ? "auto" : 60 }}
    >
      <div className="h-[60px] min-h-[60px] p-2 flex justify-between items-center">
        <FX.ShowWhenViewed useRef={FaqRef} threshold={0.25}>
          <Text.Header
            className={"!text-sm line-clamp-2 !text-black font-bold"}
          >
            {title}
          </Text.Header>
          <FaPlus
            strokeWidth={16}
            className={`${
              openAccordion === id && "-rotate-45"
            } overflow-visible transition-all text-2xl animate-fade-in-up`}
          />
        </FX.ShowWhenViewed>
      </div>
      <div className={`p-2`}>
        <Text.Body
          className={`text-lg text-dark/50 ${
            openAccordion === id ? "opacity-100" : "opacity-0 translate-y-1"
          } duration-300 transition-all`}
        >
          {children}
        </Text.Body>
      </div>
    </motion.div>
  );
};

import React, { useEffect, useState } from "react";

export default function Toggle({
  className,
  options = ["on", "off"],
  toggle = "on",
  setToggle,
}) {
  const [doneLoading, setDoneLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    let timeout = 10;

    setTimeout(() => {
      if (mounted) {
        // if toggle is not in options, set toggle to first option
        if (!options.includes(toggle)) {
          setToggle(options[0]);
        }
        setDoneLoading(true);
      }
    }, [timeout]);
    return () => (mounted = false);
  }, []);
  return (
    doneLoading && (
      <div className={`${className} p-1 rounded-full w-fit h-9 bg-dark/5`}>
        <div className="relative grid min-h-full grid-flow-col auto-cols-fr ">
          {options.map((option) => (
            <div
              onMouseUp={() => {
                if (setToggle) {
                  setToggle(option);
                }
              }}
              className={`${
                toggle === option ? "text-dark/90" : "text-dark/50"
              } z-40 h-full grid justify-center select-none items-center min-h-full w-full text-center cursor-pointer px-4`}
              key={option}
            >
              {option}
            </div>
          ))}
          <div
            className={`absolute drop-shadow-none left-0 transition-transform items-center justify-center h-full rounded-full bg-white`}
            style={{
              width: (1 / options.length) * 100 + "%",
              transform: `translateX(${options.indexOf(toggle) * 100}%)`,
            }}
          />
        </div>
      </div>
    )
  );
}

import React, { useState } from "react";
import { Button, Text } from "components";
import {
  FaBars,
  FaEnvelope,
  FaEnvelopeOpen,
  FaFolderClosed,
  FaRegFolderOpen,
  FaX,
} from "react-icons/fa6";
import { DarkLogo } from "assets";
import { motion, AnimatePresence } from "framer-motion";
import Top from "./Top";

export default function Nav() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ x: "-100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{
              x: "-100%",
              opacity: 0,
              transition: { duration: 0.25, ease: "easeIn" },
            }}
            transition={{ type: "keyframes", ease: "easeOut" }}
            className="sticky top-0 left-0 z-50 w-full overflow-hidden bg-bright h-dvh"
          >
            <FaX
              onClick={() => {
                setOpen(false);
              }}
              className="absolute text-3xl cursor-pointer right-4 top-4"
            />
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Button.Link
                href="/"
                className={`!text-4xl !transition-colors hover:text-primary`}
              >
                Home
              </Button.Link>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.15 }}
            >
              {" "}
              <Button.Link
                href="/contact"
                className={`!text-4xl !transition-colors hover:text-primary`}
              >
                Contact
              </Button.Link>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              {" "}
              <Button.Link
                href="/projects"
                className={`!text-4xl animate-[fade-in-left 1.5s ease-in-out] !transition-colors hover:text-primary`}
              >
                Projects
              </Button.Link>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <nav className="sticky top-0 z-40 border-b-2 border-black bg-bright drop-shadow-md">
        <Top />
        <div className="mx-auto max-sm:px-2 sm:w-5/6 max-w-7xl ">
          <div className="relative flex items-center justify-between h-16">
            <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-between">
              <Logo />
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex h-16 group">
                  <NavBtn
                    HoverIcon={<FaEnvelopeOpen />}
                    Icon={<FaEnvelope />}
                    href="/contact"
                  >
                    Contact
                  </NavBtn>

                  <NavBtn
                    href="/projects"
                    HoverIcon={<FaRegFolderOpen />}
                    Icon={<FaFolderClosed />}
                  >
                    Projects
                  </NavBtn>
                  <div className="relative">
                    <DivLine />
                  </div>
                </div>
              </div>
            </div>

            <MobileNav />
          </div>
        </div>
      </nav>
    </>
  );

  function NavBtn({
    href = "",
    initialState = "unselected",
    Icon,
    HoverIcon,
    children,
  }) {
    const buttonState = initialState;
    const [hovered, setHovered] = useState(false);

    return (
      <div className="relative">
        <DivLine />
        <a
          href={href}
          class={`capitalize overflow-hidden text-sm gap-1 font-medium bg-transparent text-black hover:text-bright hover:bg-black flex justify-center items-center text-center transition-colors h-full w-fit px-10 min-w-16" ${
            buttonState === "selected" && "underline underline-offset-2 "
          }`}
          aria-current="page"
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
        >
          {/*<AnimatePresence>
            {hovered && (
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.5 }}
              >
                {HoverIcon}
              </motion.div>
            )}
          </AnimatePresence>*/}
          <div className="relative w-3">
            <AnimatePresence>
              {hovered && (
                <motion.div
                  className="absolute -top-[6px]"
                  initial={{ x: 2, y: 50, rotate: 50 }}
                  animate={{
                    opacity: hovered ? 1 : 0,
                    x: 0,
                    y: 0,
                    rotate: 0,
                    transition: { delay: 0.5 },
                  }}
                  exit={
                    !hovered && {
                      x: -2,
                      y: -50,
                      rotate: -50,
                    }
                  }
                  transition={{ duration: 0.5 }}
                >
                  {HoverIcon}
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {!hovered && (
                <motion.div
                  className="absolute -top-[6px]"
                  initial={{
                    x: 0,
                    y: 0,
                    rotate: 0,
                    transition: { duration: 0 },
                  }}
                  animate={{
                    x: 0,
                    y: 0,
                    rotate: 0,
                    transition: { duration: 0 },
                  }}
                  exit={{
                    x: -2,
                    y: -50,
                    rotate: -50,
                    transition: { delay: 0.5 },
                  }}
                  transition={{ duration: 0.5 }}
                >
                  {Icon}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          {children}
        </a>
      </div>
    );
  }

  function Logo() {
    return (
      <a href="/" className="flex items-center flex-shrink-0">
        <img className="w-auto h-8" src={DarkLogo} alt="Your Company" />
      </a>
    );
  }

  function MobileNav() {
    return (
      <>
        <FaBars
          className="absolute right-0 block h-full mr-2 cursor-pointer sm:hidden"
          onClick={() => setOpen(true)}
        />
      </>
    );
  }
  function DivLine() {
    return (
      <div
        className="w-[1px] h-[65%] absolute top-1/2 -translate-y-1/2 left-0 opacity-95 my-auto"
        style={{
          background:
            "linear-gradient(to top, rgba(255,0,0,0), rgba(0, 44, 52, 1), rgba(255,0,0,0))",
        }}
      />
    );
  }
}

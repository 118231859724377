import { Text } from "components";
import React from "react";
import { FaTriangleExclamation } from "react-icons/fa6";
import { TailSpin } from "react-loading-icons";

export default function FullPageError({
  children = "Error loading content, please try again.",
}) {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-[calc(100dvh-4.1rem)] gap-5 bg-dark/5">
      <FaTriangleExclamation />
      <Text.Header
        className={"!text-xl !animate-fade-in-up-fast !text-center !text-dark"}
      >
        {children}
      </Text.Header>
    </div>
  );
}

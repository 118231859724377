import React from "react";
import { TailSpin } from "react-loading-icons";

const Button = ({
  onMouseUp,
  className,
  children,
  href = "",
  doneLoading = true,
}) => {
  if (href)
    return (
      <>
        {doneLoading ? (
          <a
            onMouseUp={onMouseUp}
            href={href}
            className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 transition-colors hover:bg-primary/5 select-none font-medium text-primary rounded-md cursor-pointer pointer-events-auto  font-montserrat w-fit bg-transparent outline outline-1 -outline-offset-1 outline-dark/20 hover:drop-shadow-lg`}
          >
            {children}
          </a>
        ) : (
          <div
            onMouseUp={onMouseUp}
            className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 transition-colors bg-primary/5 select-none font-medium text-primary rounded-md font-montserrat w-fit  outline outline-1 -outline-offset-1 outline-dark/20`}
          >
            <TailSpin
              className="h-[.875rem] w-fit mr-1 overflow-visible"
              stroke="#313638"
              strokeWidth={5}
            />{" "}
            Please Wait
          </div>
        )}
      </>
    );
  return (
    <>
      {doneLoading ? (
        <div
          onMouseUp={onMouseUp}
          className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 transition-colors hover:bg-dark/5 select-none font-medium text-dark rounded-md cursor-pointer pointer-events-auto  font-montserrat w-fit bg-transparent outline outline-1 -outline-offset-1 outline-dark/20 hover:drop-shadow-lg`}
        >
          {children}
        </div>
      ) : (
        <div
          onMouseUp={onMouseUp}
          className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 transition-colors bg-dark/5 select-none font-medium text-dark rounded-md font-montserrat w-fit  outline outline-1 -outline-offset-1 outline-dark/20`}
        >
          <TailSpin
            className="h-[.875rem] w-fit mr-1 overflow-visible"
            stroke="#313638"
            strokeWidth={5}
          />{" "}
          Please Wait
        </div>
      )}
    </>
  );
};
export default Button;

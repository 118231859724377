import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { FirestoreContext } from "context";
import {
  Badge,
  Breadcrumbs,
  Button,
  Image,
  LoadingSpinner,
  Text,
} from "components";
import { FaArrowLeft } from "react-icons/fa6";
export default function Details() {
  const { id } = useParams();
  const { getSnapshot, getData } = useContext(FirestoreContext);
  const [data, setData] = useState({});
  const [doneLoading, setDoneLoading] = useState(false);
  useEffect(() => {
    let mounted = true;
    let timeout = 50;
    const getProjectData = async () => {
      const docSnapshot = await getSnapshot("projects", id);
      const data = getData(docSnapshot);
      if (!data) return;
      document.title = "Forever Midwest | " + data.name;
      setData(data);
      console.log(data);
      setDoneLoading(true);
    };
    setTimeout(() => {
      if (mounted) {
        getProjectData();
      }
    }, timeout);
    return () => (mounted = false);
  }, []);
  if (!doneLoading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="relative w-full min-h-[calc(100dvh-4.1rem)] h-full bg-dark/5">
      <div className="flex flex-col items-center justify-start w-full h-full p-2">
        <div className="flex justify-between w-full">
          <Button.Outline href={"/projects"} className={`pointer-events-auto `}>
            <FaArrowLeft />
            Back
          </Button.Outline>
          <Breadcrumbs
            trail={[
              { link: "/projects", name: "projects" },
              { link: `/projects/${data.id}`, name: data.id },
            ]}
          />
        </div>

        <div className="flex flex-col items-center justify-center w-5/6 h-full gap-2 pb-20 max-w-7xl">
          <div className="">
            <div className="flex flex-wrap items-center justify-center gap-5 py-5">
              <Text.Header className={"!text-3xl/none text-center text-black"}>
                {data.name}
              </Text.Header>

              <Text.SubHeader className={"text-xl text-dark/50"}>
                <DateDisp date={data.date} />
              </Text.SubHeader>
            </div>
            <div className="flex justify-center gap-2 pb-5">
              {data.highlight && (
                <Badge className={"bg-primary"}>{data.highlight}</Badge>
              )}
              {data.tags?.map((tag, index) => (
                <Badge key={index}>{tag}</Badge>
              ))}
            </div>
            <div className="flex flex-col items-center justify-center w-full max-w-full gap-2 ">
              <Image src={data.image} className={"h-[20rem]"} />
              <div className="w-full">
                <Text.Body className="!text-base/none !text-pretty text-dark/50">
                  {data.shortDescription}
                </Text.Body>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  function DateDisp({ date }) {
    date = date?.toDate();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date ? new Date(date).toLocaleDateString(undefined, options) : "";
  }
}

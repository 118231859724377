import React from "react";

import { createContext, useContext } from "react";
import {
  query,
  collection,
  where,
  getDocs,
  doc,
  limit,
  updateDoc,
  orderBy,
  getDoc,
} from "firebase/firestore";
import { FirebaseContext, FunctionsContext, MessageContext } from "context";

const FirestoreContext = createContext();

const FirestoreProvider = ({ children }) => {
  const { useFirestore } = useContext(FirebaseContext);
  const { newMessage } = useContext(MessageContext);
  const { useGetCustomClaims } = useContext(FunctionsContext);
  const firestore = useFirestore();

  const projectsCollection = collection(firestore, "projects");

  async function referenceProjects() {
    try {
      const query_snapshot = query(projectsCollection, orderBy("date", "desc"));
      const data_snapshot = await getDocs(query_snapshot);
      const projects = data_snapshot.docs;
      const result = projects;
      return result;
    } catch (error) {
      console.error(error);
      newMessage({
        type: "error",
        message: "Error getting projects",
      });
    }
  }

  function getData(doc) {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
    };
  }

  function getSnapshot(collectionName, id) {
    try {
      const collectionRef = collection(firestore, collectionName);
      const docRef = doc(collectionRef, id);
      return getDoc(docRef).then((doc) => doc);
    } catch (error) {
      console.error(error);
      newMessage({
        type: "error",
        message: "Error getting snapshot",
      });
    }
  }

  const values = {
    getData,
    referenceProjects,
    getSnapshot,
  };
  return (
    <FirestoreContext.Provider value={values}>
      {children}
    </FirestoreContext.Provider>
  );
};

export { FirestoreProvider, FirestoreContext };

import { useState, useEffect } from "react";
import { useMotionValue, useSpring } from "framer-motion";

const useMousePosition = () => {
  const mousePosition = { x: useMotionValue(0), y: useMotionValue(0) };
  const smoothOptions = { damping: 20, stiffness: 200, mass: 0.5 };

  const smoothMouse = {
    x: useSpring(mousePosition.x, smoothOptions),
    y: useSpring(mousePosition.y, smoothOptions),
  };
  const updateMousePosition = (e) => {
    mousePosition.x.set(e.clientX);
    mousePosition.y.set(e.clientY + window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, [updateMousePosition]);

  return smoothMouse;
};

export default useMousePosition;

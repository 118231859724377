import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import Process from "./Process";
import Globe from "./Globe";
import Stats from "./Stats";
import FAQ from "./FAQ";
import FinalCTA from "./FinalCTA";
export default function Home() {
  useEffect(() => {
    let mounted = true;
    let timeout = 50;

    setTimeout(() => {
      if (mounted) {
        document.title = "Forever Midwest";
      }
    }, [timeout]);
  }, []);
  return (
    <div className="block w-full h-full">
      <Hero />
      <Process />
      <Globe />
      <Stats />
      <FAQ />
      <FinalCTA />
    </div>
  );
}

import React from "react";

const Button = ({
  onMouseUp,
  href,
  className,
  children,
  doneLoading = true,
}) => {
  if (href) {
    return (
      <>
        <a
          href={href}
          className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 hover:underline transition-colors underline-offset-4 select-none font-medium text-dark rounded-md cursor-pointer pointer-events-auto  font-montserrat w-fit bg-transparent`}
        >
          {children}
        </a>
      </>
    );
  }
  return (
    <>
      <div
        onMouseUp={onMouseUp}
        className={`${className} flex justify-center items-center gap-1 py-3 my-1 text-sm/none tracking-tight px-4 hover:underline transition-colors underline-offset-4 select-none font-medium text-dark rounded-md cursor-pointer pointer-events-auto  font-montserrat w-fit bg-transparent`}
      >
        {children}
      </div>
    </>
  );
};
export default Button;

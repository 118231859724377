import Ghost from "./Ghost";
import Link from "./Link";
import Primary from "./Primary";
import Secondary from "./Secondary";
import Outline from "./Outline";
import Footer from "./Footer";
const Button = {
  Ghost,
  Link,
  Primary,
  Secondary,
  Outline,
  Footer,
};
export default Button;

import { Form, Text } from "components";
import { FunctionsContext } from "context";
import React, { useContext, useEffect, useState } from "react";

export default function Contact() {
  useEffect(() => {
    let mounted = true;
    let timeout = 50;

    setTimeout(() => {
      if (mounted) {
        document.title = "Forever Midwest | Contact";
      }
    }, [timeout]);
  }, []);
  return (
    <div className="flex items-start justify-center flex-grow w-full min-h-[calc(100dvh-4.1rem)] bg-dark/5">
      <div className="flex flex-col w-full min-h-full gap-5 px-2 py-10 sm:px-0 sm:w-5/6 max-w-7xl">
        <div className="flex flex-col items-start justify-center w-full gap-2 pt-2 sm:jusify-start ">
          <Text.Header>Contact</Text.Header>
          <FormBody />
        </div>
      </div>
    </div>
  );
  function FormBody() {
    const { UseSendContactMessage } = useContext(FunctionsContext);

    const [btnDoneLoading, setDoneLoading] = useState(true);
    const [form, setForm] = useState({
      email: "",
      message: "",
    });
    const [submit, setSubmit] = useState(false);

    function handleUpdate(e) {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }

    function handleSubmit(e) {
      let minTimeout = 500;
      e.preventDefault();
      setDoneLoading(false);
      setTimeout(async () => {
        let response = await UseSendContactMessage(form.email, form.message);
        if (response.success) {
          setForm({
            email: "",
            message: "",
          });
          setDoneLoading(true);
          setSubmit(true);
        }
      }, minTimeout);
    }

    if (submit) {
      return (
        <div
          className={`sm:w-[500px] max-sm:p-0 !transition-all delay-200 duration-300 mx-auto w-full sm:bg-bright sm:outline outline-dark/10 outline-1 p-4 flex h-full flex-col gap-1 rounded-md`}
        >
          <Text.Header
            className={
              "text-2xl opacity-0 sm:opacity-100 transition-all sm:h-full overflow-hidden duration-300 delay-500"
            }
          >
            Thank You For Your Message
          </Text.Header>
          <Text.Body>We will get back to you as soon as possible. </Text.Body>
        </div>
      );
    }

    return (
      <Form.Form
        onSubmit={handleSubmit}
        className={
          "sm:w-[500px] max-sm:p-0 !transition-all delay-200 duration-300 mx-auto w-full"
        }
      >
        <Text.Header
          className={
            "text-2xl opacity-0 sm:opacity-100 transition-all sm:h-full overflow-hidden duration-300 delay-500"
          }
          disableAnimation
        >
          Form
        </Text.Header>

        <Form.TextInput
          required
          label={"Your Email"}
          name={"email"}
          type="email"
          value={form.email}
          onChange={(e) => {
            handleUpdate(e);
          }}
        >
          you@company.com
        </Form.TextInput>
        <Form.LargeTextInput
          required
          className={"mb-8"}
          label={"Message"}
          name={"message"}
          type="text"
          value={form.message}
          onChange={(e) => {
            handleUpdate(e);
          }}
        >
          Write your message here...
        </Form.LargeTextInput>
        <Form.FormButton className={"mx-auto"} doneLoading={btnDoneLoading}>
          Send
        </Form.FormButton>
      </Form.Form>
    );
  }
}

import React from "react";

import { motion } from "framer-motion";
import { useMousePosition, useScrollPosition } from "utils";

export default function Globe() {
  const { x, y } = useMousePosition();

  const size = 200;

  return (
    <div
      className={`${
        false && "opacity-0"
      } absolute  transition-opacity duration-400 h-[calc(100%+50px)]  overflow-hidden pointer-events-none  -top-[50px] -left-[50px] w-[calc(100%+50px)] z-10`}
    >
      <motion.div
        style={{ x: x, y: y }}
        animate={{ rotate: 360, opacity: 1 }}
        transition={{ duration: 4, ease: "linear", repeat: Infinity }}
        className={` absolute top-0 left-0 z-20  flex-col items-center backdrop-blur-sm invert hidden rounded-full pointer-events-none md:flex`}
      >
        <svg viewBox={`0 0 100 100`} width={100} height={100} className="">
          <defs>
            <path
              id="circle"
              d="
        M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0"
            />
          </defs>
          <text font-size="6">
            <textPath href="#circle">
              Distribution - Recording - Marketing - Collective - Management -
              Publishing - Booking -
            </textPath>
          </text>
        </svg>
      </motion.div>
    </div>
  );
}

import { RecordingStudio } from "assets";
import { Badge, Image, Text } from "components";
import { FirestoreContext, WindowContext } from "context";
import React, { useContext, useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function ({
  src = "",
  alt = "",
  name = "Project Name",
  date,
  description = "Project Description",
  doc,
}) {
  const [hover, setHover] = useState(false);
  const [data, setData] = useState({});
  const [doneLoading, setDoneLoading] = useState(false);
  const { mobile } = useContext(WindowContext);
  const { getData } = useContext(FirestoreContext);
  const navigate = useNavigate();
  useEffect(() => {
    let mounted = true;
    let timeout = 50;
    const useGetData = async () => {
      const data = await getData(doc);
      if (!data) return;
      setData(data);
      setDoneLoading(true);
      console.log(data);
    };
    setTimeout(() => {
      if (mounted && doc) {
        useGetData();
      }
    }, timeout);
    return () => (mounted = false);
  }, []);
  return (
    <motion.a
      onMouseEnter={() => {
        if (mobile) return;
        setHover(true);
      }}
      onMouseLeave={() => {
        if (mobile) return;
        setHover(false);
      }}
      href={`/projects/${doc.id}`}
      whileHover={{
        scale: 1.05,
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
      className="relative flex flex-col w-full gap-2 p-2 pb-10 antialiased duration-500 rounded-lg cursor-pointer sm:flex-row sm:h-64 sm:max-h-64 bg-bright sm:pb-2"
    >
      <Image
        className={"h-full sm:w-[30%] w-full drop-shadow-md "}
        src={data.image}
        alt={data.alt}
      />
      <div className="sm:max-w-[70%]  overflow-x-hidden overflow-y-hidden h-full max-w-full">
        <Text.Header className={"!text-3xl/none pb-2 !w-full text-black"}>
          {data.name}
        </Text.Header>

        <div className="relative flex flex-row justify-start w-full max-w-full gap-2 pb-2 overflow-hidden ">
          <div className="absolute bg-gradient-to-r from-transparent to-bright right-0 w-[25px] top-0 h-full" />
          {data.highlight && (
            <Badge className={"bg-primary"}>{data.highlight}</Badge>
          )}

          {data.tags?.map((tag, index) => (
            <Badge className={""} key={index}>
              {tag}
            </Badge>
          ))}
        </div>
        <Text.SubHeader className={"text-lg pb-2 !w-full text-dark/50"}>
          <DateDisp date={data.date} />
        </Text.SubHeader>
        <Text.Body className={"text-pretty line-clamp-3 pr-8 !min-w-min"}>
          {data.shortDescription}
        </Text.Body>
      </div>
      {(hover || mobile) && <LinkPointer />}
    </motion.a>
  );
  function LinkPointer() {
    return (
      <div className="absolute bottom-0 right-0 flex items-center justify-center gap-2 p-2 pointer-events-none text-dark ">
        <Text.Body className={"w-fit !text-sm"}>View Project</Text.Body>
        <FaArrowRight className="animate-fade-in-up" />
      </div>
    );
  }
  function DateDisp({ date }) {
    date = date?.toDate();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date ? new Date(date).toLocaleDateString(undefined, options) : "";
  }
}

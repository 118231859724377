import React from "react";

const Button = ({ href = "/", className, children, doneLoading = true }) => {
  return (
    <>
      <a
        href={href}
        className={`${className} text-sm/none tracking-tight pb-3  hover:underline transition-colors underline-offset-4 select-none font-medium text-dark rounded-md cursor-pointer pointer-events-auto  font-montserrat w-fit bg-transparent`}
      >
        {children}
      </a>
    </>
  );
};
export default Button;

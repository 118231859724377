import React, { createContext, useState } from "react";

const MessageContext = createContext();

const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  function newMessage({ type = "error", duration = 5000, message }) {
    let obj = {
      type: type,
      duration: duration,
      message: message,
      key: Math.random() * 1000,
      shown: true,
    };
    setMessages((prevMessages) => [...prevMessages, obj]);
    setTimeout(() => {
      // remove from array
      setMessages((prevMessages) =>
        prevMessages.filter((item) => item.key !== obj.key)
      );
    }, [duration]);
  }

  const useMessages =
    (() => {
      return messages;
    },
    [messages]);

  return (
    <MessageContext.Provider
      value={{
        useMessages,
        messages,
        newMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export { MessageContext, MessageProvider };

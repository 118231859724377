import React, { useContext, useEffect, useState } from "react";
import { MessageContext } from "context";
import Message from "./Message";
export default function Messages() {
  const [messagesRef, setMessagesRef] = useState([]);
  const { messages } = useContext(MessageContext);
  useEffect(() => {
    setMessagesRef(messages);
  }, [messages]);

  return (
    <div className="absolute z-50 flex flex-col items-end max-w-full gap-2 p-2 whitespace-normal pointer-events-none w-svw h-fit">
      {messagesRef.map((message) => (
        <Message
          key={message.key} // Add a unique key prop
          type={message.type}
          duration={message.duration}
          shown={message.shown}
        >
          {message.message}
        </Message>
      ))}
    </div>
  );
}

import React from "react";

export default function TextInput({
  type = "text",
  maxLength,
  className,
  required,
  children,
  value,
  label,
  onChange,
  name,
  error,
  allowResize,
}) {
  return (
    <div className={`${className} h-32 w-full`}>
      {label && (
        <label className="text-xs ">
          {label}
          {required && " *"}
        </label>
      )}
      <textarea
        placeholder={children}
        type={type}
        required
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        className={`${error && "!ring-red-500"} ${
          !allowResize && "resize-none"
        } w-full  h-32 pointer-events-auto p-2 ring-dark/50 ring-1 ring-inset rounded-md bg-transparent outline-dark/10 outline-1`}
      />
    </div>
  );
}

import React from "react";
import {
  Home,
  Artists,
  Projects,
  ProjectDetails,
  Privacy,
  Terms,
  About,
  Contact,
} from "pages";
import {
  createRoutesFromElements,
  RouterProvider,
  Route,
  createBrowserRouter,
} from "react-router-dom";
import FullPageError from "components/FullPageError";
export default function Router() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Home />} />

        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />

        <Route path="projects/:id" element={<ProjectDetails />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/*"
          element={<FullPageError>{"404 Error: No Page Found."}</FullPageError>}
        />
      </>
    )
  );

  return <RouterProvider router={router} />;
}

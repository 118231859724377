import React from "react";

export default function Breadcrumbs({
  className,
  trail = [{ link: "", name: "" }],
  children,
}) {
  return (
    <div
      className={`${className} pointer-events-auto select-none mt-2 ml-2 h-fit max-h-fit w-fit px-2 py-1 text-dark text-sm  bg-dark/5 rounded`}
    >
      {trail.map((entry) => (
        <>
          <a href={`${entry.link}`} className="hover:underline">
            {entry.name}
          </a>
          {" / "}
        </>
      ))}
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Card from "./Card";
import { Text } from "components";
import { FaSuitcase } from "react-icons/fa6";
import { FirestoreContext } from "context";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import FullPageError from "components/FullPageError";

export default function Projects() {
  const { referenceProjects } = useContext(FirestoreContext);
  const [doneLoading, setDoneLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    let mounted = true;
    let timeout = 50;
    const getRef = async () => {
      const projectRefs = await referenceProjects();
      if (!projectRefs) return;
      document.title = "Forever Midwest | Projects";
      setProjects(projectRefs);
      setDoneLoading(true);
    };
    setTimeout(() => {
      if (mounted) {
        getRef();
      }
    }, timeout);
    return () => (mounted = false);
  }, [referenceProjects]);

  if (!doneLoading) {
    return <LoadingSpinner />;
  } else if (projects.length === 0) {
    return <FullPageError />;
  }

  return (
    <div className="flex items-start justify-center flex-grow w-full min-h-full h bg-dark/5">
      <div className="flex flex-col w-full min-h-full gap-5 px-10 py-10 max-w-7xl">
        <div className="flex flex-col items-center justify-center w-full gap-2 p-2 pb-0 sm:flex-row sm:justify-start ">
          <FaSuitcase className="p-2 min-h-16 min-w-16 !object-none text-[100%] text-bright bg-primary rounded-xl  drop-shadow-md" />
          <div className="flex flex-col items-center justify-center gap-2 text-2xl sm:items-start w-fit ">
            <Text.Header className={"!text-3xl/none !text-black"}>
              Projects
            </Text.Header>

            <Text.Body className="!text-base/none sm:text-left text-center text-dark/50">
              Explore what we've been working on.
            </Text.Body>
          </div>
        </div>
        {projects.map((doc, index) => (
          <Card doc={doc} key={index} />
        ))}
      </div>
    </div>
  );
}

import { FX, Text } from "components";
import React, { useEffect, useRef, useState } from "react";

export default function Stats() {
  const ref = useRef(null);
  return (
    <div
      ref={ref}
      className="flex items-center justify-center w-full h-full p-10 sm:px-0 px-2 bg-dark/5"
    >
      <div className="grid w-full grid-cols-12 gap-2 sm:w-5/6 max-w-7xl">
        <ColorBox
          stat={"4M"}
          unit={"Worldwide Impressions"}
          className={"bg-primary sm:col-span-10"}
        ></ColorBox>
        <ColorBox
          stat={"6K"}
          unit={"Tracks"}
          darkFont
          className={"bg-primary/40 backdrop-blur-sm sm:col-span-2"}
        ></ColorBox>
        <ColorBox
          stat={"250"}
          unit={"Artists"}
          className={"bg-black sm:col-span-8"}
        >
          <>
            <div className="bg-black w-[10rem] h-[10rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10"></div>
            <Hazard />
          </>
        </ColorBox>
        <ColorBox
          stat={"750K"}
          unit={"Total Streams"}
          className={"bg-primary sm:col-span-4"}
        ></ColorBox>
      </div>
    </div>
  );
  function ColorBox({ className, stat, unit, darkFont = false, children }) {
    return (
      <div
        className={`${className} hover:scale-105 hover:z-10 hover:drop-shadow-lg transition-[transform,shadows] duration-500 relative col-span-12 flex gap-3 h-[20rem] overflow-hidden justify-center items-center flex-col text-center  w-full rounded-3xl`}
      >
        <FX.ShowWhenViewed useRef={ref}>
          <Text.Header
            className={`${!darkFont && "!text-bright"} z-20 text-6xl font-bold`}
          >
            {stat}
          </Text.Header>
          <Text.Header
            className={`${
              !darkFont && "!text-bright"
            } z-20 text-base font-mono`}
          >
            <FX.Matrix>{unit}</FX.Matrix>
          </Text.Header>
        </FX.ShowWhenViewed>
        {children}
      </div>
    );
  }
  function Hazard() {
    const map = new Array(17).fill(0);
    return (
      <div className="absolute top-0 left-0 z-0 flex items-center justify-center w-full h-full overflow-clip">
        <div className="flex items-center justify-center h-[900px] rotate-45 left-right">
          {map.map((i) => (
            <>
              <div className="bg-white  h-full w-[25px]" />
              <div className="bg-black  h-full w-[25px]" />
            </>
          ))}
        </div>
      </div>
    );
  }
}

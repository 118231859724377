import { Button, FX, Text } from "components";
import React, { useRef } from "react";

export default function FinalCTA() {
  const finalCTARef = useRef(null);
  return (
    <div
      ref={finalCTARef}
      className="flex flex-col items-center justify-start w-full h-full gap-5 p-10 pb-20 bg-dark/5"
    >
      <FX.ShowWhenViewed useRef={finalCTARef}>
        <Text.Header className={"text-center !text-pretty"}>
          Your Journey Shouldn't End Here.
        </Text.Header>
        <Text.SubHeader className={"text-center !text-pretty"}>
          Let's make something great together.
        </Text.SubHeader>
        <Button.Primary href={"/projects"}>What We've Done</Button.Primary>
      </FX.ShowWhenViewed>
    </div>
  );
}

import React from "react";

export default function Dropdown({
  className,
  label,
  children,
  type,
  maxLength,
  defaultValue = "default",
  options = [],
  name,
}) {
  return (
    <>
      <div className="w-full text-base">
        {label && <label className="text-xs ">{label}</label>}
        <select
          name={name}
          defaultValue={defaultValue}
          className={`${className} w-full  space-y-2 gap-2 h-full text-dark/60 pointer-events-auto p-2 pl-1 ring-dark/50 ring-1 ring-inset rounded-md bg-transparent outline-dark/10 outline-1`}
        >
          <option value={"default"} hidden>
            Pick one
          </option>
          {options.map((option) => (
            <option className="text-dark" value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

import { Text } from "components";
import React from "react";
import { TailSpin } from "react-loading-icons";

export default function LoadingSpinner() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-[calc(100dvh-4.1rem)] gap-5 bg-dark/5">
      <TailSpin
        stroke={"#000"}
        fill={"#000"}
        strokeWidth={5}
        className="overflow-visible animate-fade-in-left"
      />
      <Text.Header
        className={"!text-xl !animate-fade-in-up !text-center !text-dark"}
      >
        Please wait…
      </Text.Header>
    </div>
  );
}

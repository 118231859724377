import React from "react";
import { TailSpin } from "react-loading-icons";

export default function Secondary(
  onMouseUp,
  className,
  children,
  doneLoading = true
) {
  return (
    <>
      {doneLoading ? (
        <div
          onMouseUp={onMouseUp}
          className={`${className} flex justify-center items-center gap-1 py-3 my-1 px-4 text-sm/none select-none tracking-tight transition-colors hover:bg-dark/5 text-dark rounded-md cursor-pointer pointer-events-auto   font-montserrat w-fit bg-dark/10 hover:drop-shadow-lg`}
        >
          {children}
        </div>
      ) : (
        <div
          onMouseUp={onMouseUp}
          className={`${className} flex justify-center items-center gap-1 py-3 my-1 px-4 text-sm/none select-none tracking-tight transition-colors bg-dark/5 text-dark rounded-md font-montserrat w-fit`}
        >
          <TailSpin
            className="h-[.875rem] w-fit mr-1  overflow-visible"
            stroke="#313638"
            strokeWidth={5}
          />{" "}
          Please Wait
        </div>
      )}{" "}
    </>
  );
}

import React from "react";

import { createContext, useContext } from "react";
import { FirebaseContext } from "context";

const StorageContext = createContext();

const StorageProvider = ({ children }) => {
  const { useStorage } = useContext(FirebaseContext);
  const storage = useStorage();
  const values = {};
  return (
    <StorageContext.Provider value={values}>{children}</StorageContext.Provider>
  );
};

export { StorageProvider, StorageContext };

import React, { useEffect, useState } from "react";

export default function TextInput({
  type = "text",
  maxLength,
  className,
  required,
  children,
  value,
  label,
  onChange,
  name,
  error,
}) {
  const [charLeft, setCharLeft] = useState(maxLength || 0);
  useEffect(() => {
    if (value) {
      setCharLeft(maxLength - value.length || 0);
    }
  }, [value]);
  return (
    <div className={`${className} relative w-full`}>
      {label && (
        <label className="text-xs ">
          {label}
          {required && " *"}
        </label>
      )}
      <input
        placeholder={children}
        type={type}
        required
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        className={`${error && "!ring-red-500"} ${
          maxLength && "!pr-4"
        } w-full h-full pointer-events-auto p-2 ring-dark/50 ring-1 ring-inset rounded-md bg-transparent outline-dark/10 outline-1`}
      />
      {maxLength && (
        <div
          key={charLeft}
          className="absolute text-xs translate-y-1 right-1 top-1/2"
        >
          {value ? charLeft : maxLength}
        </div>
      )}
    </div>
  );
}

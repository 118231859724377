import React from "react";

const Heading = ({
  className,
  level = 1,
  style,
  children,
  flex,
  lineClamp2,
  disableAnimation,
}) => {
  const Tag = `h${level}`;

  return (
    <div
      style={style}
      className={`${className} text-4xl/relaxed font-montserrat text-black font-bold break-words ${
        !disableAnimation && "animate-fade-in-left"
      } hyphens-auto tracking-wide`}
    >
      <Tag className={`${flex && "flex gap-2"}`}>{children}</Tag>
    </div>
  );
};

export default Heading;

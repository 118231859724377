import React from "react";

export default function Form({ className, onSubmit, ref, children }) {
  return (
    <>
      {/* This is the form that the rest of the components sit in */}
      <form
        onSubmit={onSubmit}
        className={`${className} sm:bg-bright transition-all delay-200 duration-300 sm:outline outline-dark/10 outline-1 p-4 flex h-full flex-col gap-1 rounded-md`}
      >
        {children}
      </form>
    </>
  );
}
